<template>
  <div id="publish">
    <div class="content">
      <div class="title">
        <i class="el-icon-edit" /> 标题:
        <el-input size="small" placeholder="请输入内容" v-model="title"/>
      </div>
      <div class="classify">
        <i class="el-icon-files" /> 分类:
        <el-select size="small" v-model="classify" clearable placeholder="请选择">
          <el-option v-for="item in categoryList" :key="item.id" :label="item.name"  :value="item.id" />
        </el-select>
      </div>
      <div class="comment">
        <i class="el-icon-s-release" /> 是否可以评论:
        <el-select size="small" v-model="comment" clearable placeholder="请选择" >
          <el-option v-for="item in commentList" :key="item.value" :label="item.name" :value="item.value" />
        </el-select>
      </div>
      <el-button size="small" round v-if="isEdit" @click="save(2)">
        发布
      </el-button>
      <el-button size="small" round v-if="isEdit" @click="save(1)">
        保存
      </el-button>
      <el-button size="small" round v-if="!isEdit" @click="saveEdit()" >
        保存修改
      </el-button>
      <el-button size="small" round v-if="!isEdit" @click="routerMyBlog()" >
        返回
      </el-button>
    </div>
    <div class="contentData">
      <QuillEditor v-model="content" style="width: 100%;"/>
    </div>
   
    <!-- <b-container class="bv-example-row">
      <b-row style="height: 1vh"/>
      <b-row >
        <b-col sm="1.5" class="ml-md-auto p-1">
          <div style="font-size: 2vw;">
            <i class="el-icon-edit" />
            标题:
          </div>
        </b-col>
        <b-col class="ml-md-auto p-1">
          <el-input placeholder="请输入内容" v-model="title" />
        </b-col>
      </b-row>
      <b-row >
        <b-col sm="1.5" class="ml-md-auto p-1">
          <div style="font-size: 2vw;">
            <i class="el-icon-edit" />
            封面:
          </div>
        </b-col>
        <b-col class="ml-md-auto p-1">
          <el-upload
          class="upload-demo"
          :action="serverUrl"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :before-upload="beforeUpload"
          multiple
          :limit="3"
          :headers="header"
          :on-exceed="handleExceed"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :file-list="fileList" >
            <el-button size="small" type="primary" >
              点击上传
            </el-button>
            <div slot="tip" class="el-upload__tip" >
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </b-col>
      </b-row>
      <b-row >
        <b-col sm="1.5" class="ml-md-auto p-1">
          <div style="font-size: 2vw;">
            <i class="el-icon-files" />
            分类:
          </div>
        </b-col>
        <b-col class="ml-md-auto p-1">
          <el-select v-model="classify" clearable placeholder="请选择">
            <el-option v-for="item in categoryList" :key="item.blogCategoryId" :label="item.name"  :value="item.blogCategoryId" />
          </el-select>
        </b-col>
        <b-col sm="1.5" class="ml-md-auto p-1">
          <div style="font-size: 2vw;">
            <i class="el-icon-s-release" />
            是否可以评论:
          </div>
        </b-col>
        <b-col class="ml-md-auto p-1">
          <el-select v-model="comment" clearable placeholder="请选择" >
            <el-option v-for="item in commentList" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="ml-md-auto p-1" style="height: 500px">
          <QuillEditor v-model="content" style="height: 100%;" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="ml-md-auto p-1" style="text-align: right">
          <b-button pill variant="info" v-if="isEdit" @click="save(2)">
            发布
          </b-button>
          <b-button pill variant="info" v-if="isEdit" @click="save(1)">
            保存
          </b-button>
          <b-button pill variant="info" v-if="!isEdit" @click="saveEdit()" >
            保存修改
          </b-button>
          <b-button pill variant="info" v-if="!isEdit" @click="routerMyBlog()" >
            返回
          </b-button>
        </b-col>
      </b-row>
    </b-container> -->
    
    
  </div>
</template>

<script>
import QuillEditor from '../../components/quilleditor/quill-editor'
import urlConfig from '@/config/config.js'
export default {
  components: {
    QuillEditor // 组件还是要写的，和import名保持一致。
  },
  name: 'Publish',
  props: {
  },
  data () {
    return {
      serverUrl: urlConfig.url + '/api/fast/upload',
      header: {
        Authorization: this.$cookies.get('Authorization')
        // token: sessionStorage.token
      }, // 有的图片服务器要求请求头需要有token
      fileList: [],
      content: '',
      classify: '',
      title: '',
      blogInfo: '',
      cover: '',
      coverList: [],
      parameterCategory: {},
      categoryList: {},
      comment: '',
      commentList: [
        {
          value: 1,
          name: '可以评论'
        },
        {
          value: 0,
          name: '不可以评论'
        }
      ],
      blogId: '',
      isEdit: false
    }
  },
  mounted () {
    this.id = this.$route.query.blogId
    if (this.id === undefined || this.id === '') {
      this.isEdit = true
    }
    this.blogCategorySelect()
    if (!this.isEdit) {
      this.selectBlog()
    }
  },
  methods: {
    // 文件上传前的钩子，数为上传的文件
    beforeUpload (file) {
      // 判断图片是否大于2M
      const isLt2M = file.size / 1024 / 1024 < 2
      // const fileType = file.name.substring(file.name.lastIndexOf('.')+1)
      // 判断图片的类型
      const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
      if (!isJpg) {
        this.$message.error('只能上传jpg, jpeg, png, gif格式的图片！')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false
      }
    },
    // 删除文件
    handleRemove (file, fileList) {
      for (let i = 0; i < this.coverList.length; i++) {
        if (this.coverList[i] === file.response.data.thumbnailUrl) {
          this.coverList.splice(i, 1)
          break
        }
      }
    },
    // 预览
    handlePreview (file) {
      console.log(file)
      this.$alert('<img style="width: 100%; height: 100%" src="' + file.response.data.urlId + '">', '图片预览', {
        dangerouslyUseHTMLString: true
      })
    },
    // 限制数量
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 删除提示
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 上传成功时的钩子
    uploadSuccess (res, file, fileList) {
      if (res.code === 200) {
        this.coverList.push(res.data.thumbnailUrl)
        return this.$message({
          message: '上传成功',
          type: 'success'
        })
      }
      if (res.status === 403 || res.status === 401) {
        this.routerPath('/login')
      }
      for (let i = 0; i < this.coverList.length; i++) {
        if (this.coverList[i] === file.response.data.thumbnailUrl) {
          this.coverList.splice(i, 1)
          fileList = this.coverList
          break
        }
      }
      return this.$message.error(res.msg)
    },
    uploadError (res, file, fileList) {
      console.log(res)
      if (res.status === 403 || res.status === 401) {
        this.routerPath('/login')
      }
    },
    // 返回页面
    routerPath (pa) {
      if (this.$route.path !== pa) {
        this.$router.push({ path: pa })
      }
    },
    // 返回文章管理
    routerMyBlog () {
      this.routerPath('myBlog')
    },
    // 查询分类
    blogCategorySelect () {
      this.parameterCategory = {
        status : 0
      }
      const _this = this
      $.ajax({
        type: 'GET',
        url: urlConfig.url + '/api/blogCategory/blogCategorySelect',
        data: _this.parameterCategory,
        success: function (data, state, ret) {
          if (ret.status === 200) {
            if (data.code === 200) {
              _this.categoryList = data.data.records
            } else {
              _this.$message.error(data.msg)
            }
          } else {
            _this.routerPath('/login')
          }
        },
        error: function (ret) {
          if (ret.status === 403 || ret.status === 401) {
            _this.routerPath('/login')
          }
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
          xhr.withCredentials = true
        },
        xhrFields: {
          withCredentials: true
        }
      })
    },
    // 保存或者发布
    save (val) {
      this.cover = ''
      this.coverList.forEach(val => {
        this.cover += val + ','
      })
      this.cover = this.cover.slice(0, this.cover.length - 1)
      this.blogInfo = {}
      this.blogInfo.title = this.title
      this.blogInfo.content = this.content
      this.blogInfo.commentFlag = this.comment
      this.blogInfo.status = val
      this.blogInfo.blogCategoryId = this.classify
      this.blogInfo.cover = this.cover
      const _this = this
      $.ajax({
        type: 'POST',
        url: urlConfig.url + '/api/blog/blogAdd',
        data: JSON.stringify(_this.blogInfo),
        contentType: 'application/json',
        jsonp: 'callback',
        success: function (data, state, ret) {
          if (ret.status === 200) {
            if (data.code === 200) {
              _this.$message({
                message: data.msg,
                type: 'success'
              })
              _this.cover = '';
              _this.title = '';
              _this.content = '';
              _this.comment = '';
              _this.val = '';
              _this.classify = '';
            } else {
              _this.$message.error(data.msg)
            }
          } else {
            _this.routerPath('/login')
          }
        },
        error: function (ret) {
          if (ret.status === 403 || ret.status === 401) {
            _this.routerPath('/login')
          }
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
          xhr.withCredentials = true
        },
        xhrFields: {
          withCredentials: true
        }
      })
    },
    // 编辑
    saveEdit () {
      this.cover = ''
      this.coverList.forEach(val => {
        this.cover += val + ','
      })
      this.cover = this.cover.slice(0, this.cover.length - 1)
      this.blogInfo = {}
      this.blogInfo.title = this.title
      this.blogInfo.content = this.content
      this.blogInfo.commentFlag = this.comment
      this.blogInfo.blogCategoryId = this.classify
      this.blogInfo.cover = this.cover
      this.blogInfo.id = this.id
      const _this = this
      $.ajax({
        type: 'POST',
        url: urlConfig.url + '/api/blog/blogEdit',
        data: JSON.stringify(_this.blogInfo),
        contentType: 'application/json',
        jsonp: 'callback',
        success: function (data, state, ret) {
          if (ret.status === 200) {
            if (data.code === 200) {
              _this.$message({
                message: data.msg,
                type: 'success'
              })
              _this.routerMyBlog()
            } else {
              _this.$message.error(data.msg)
            }
          } else {
            _this.routerPath('/login')
          }
        },
        error: function (ret) {
          if (ret.status === 403 || ret.status === 401) {
            _this.routerPath('/login')
          }
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
          xhr.withCredentials = true
        },
        xhrFields: {
          withCredentials: true
        }
      })
    },
    // 查询
    selectBlog () {
      const _this = this
      $.ajax({
        type: 'GET',
        url: urlConfig.url + '/api/blog/getBlogAndImg',
        data: {"blogId": this.id},
        success: function (data, state, ret) {
          if (ret.status === 200) {
            if (data.code === 200) {
              _this.coverList = []
              _this.classify = data.data.blog.blogCategoryId
              _this.title = data.data.blog.title
              _this.comment = data.data.blog.commentFlag
              _this.content = data.data.blog.content
              if (data.data.fileList) {
                data.data.fileList.forEach(val => {
                  const f = {
                    name: val.fileName,
                    response: {
                      data: {
                        urlId: val.url,
                        thumbnailUrl: val.url
                      }
                    }
                  }
                  _this.fileList.push(f)
                  _this.coverList.push(val.url)
                })
              }
            } else {
              _this.$message.error(data.msg)
            }
          } else {
            _this.routerPath('/login')
          }
        },
        error: function (ret) {
          if (ret.status === 403 || ret.status === 401) {
            _this.routerPath('/login')
          }
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
          xhr.withCredentials = true
        },
        xhrFields: {
          withCredentials: true
        }
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #publish {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .content {
    display: flex;
    margin: 20px 50px;
    padding: 5px;
    background: #ffffff;
    border-radius:  30px 30px 30px 30px;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;  
    align-items: center;
  }
  .title {
    width: 30%;
    float: left;
  }
  .title .el-input {
    width: 70%;
  }
  .classify {
    width: 30%;
    float: left;
  }
  .classify .el-select {
    width: 70%
  }
  .comment {
    width: 35%;
    float: left;
  }
  .comment .el-select {
    width: 70%
  }
  .contentData {
    margin: 0px 50px 20px 50px;
    height: calc(100vh - 220px);
    display: flex;
  }
  
</style>
